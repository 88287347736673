// Pinia Store
import { useDocumentTitle } from '@/composables/useDocumentTitle';
import { defineStore, skipHydrate } from 'pinia';
import { ref, computed } from 'vue';
import numeral from 'numeral';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { isDebugMode } from '@/globalFlags';
import { getApiTokenFromCookie } from './cookieStorage';

export const useApplicationStore = defineStore('applicationStore', () => {
  const appCriticalInitFinished = ref(false);
  const appTitle = ref('GAT-Ship');
  const appIsInitialized = ref(false);
  const agencyIdSelected = ref<number | null>(null);
  // "debugMode: true" endres til "debugMode: false" i bygge prosessen.
  const debugMode = computed(() => {
    if (window.location.hostname !== 'localhost') {
      return false;
    }
    return isDebugMode;
  });
  const lastError = ref({});
  // const unsavedDataOnCurrentPage = ref(false);
  // const unsavedDialogPage = ref(undefined);
  const unsavedPages = ref<any[]>([]);

  const traversableList = ref({
    showCounter: false,
    currentIndex: undefined as undefined | number,
    items: [] as any[],
  });
  const clients = ref<{
    lastClientsUsed: any[];
  }>({
    lastClientsUsed: [],
  });
  const isCRM = ref(false);
  const version = ref('18.09.2024 09:31');
  const varcharColumns = ref<null | { [key: string]: any }>(null);
  const emailListIsDirty = ref(false);
  /** Used for the document generator */
  const appStatus = ref({
    PortCall: {},
    VesselId: undefined,
    SetupID: undefined,
  });

  const agencySettings = ref<any[]>([]);
  const globalSettings = ref<{ [key: string]: any }>({});
  const featureCodes = ref<any[]>([]);
  const settings = ref({
    dark: null as null | boolean, // null until retrived from db
    numberFormat: 'en',
    dateFormat: 'DD.MM.YYYY',
  });

  const user = ref({
    loaded: false, // will be set to true when user data is retrieved from backend
    apiToken: getApiTokenFromCookie() ?? '',
    externalClientId: null as null | string | number,
    fullName: null,
    userName: null as null | string,
    userNameLong: null as null | string,
    userId: null,
    defaultAgencyId: null,
    internal: false,
    favorites: [] as any[],
    userRights: {} as Record<string, any>,
    userSettings: {
      crewChange: {
        denseTables: null,
        showTables: null,
      },
      whiteBoards: {
        showCallsFromAllAgencies: false,
      },
    },
    upcomingTasks: {
      transport: false,
      accommodation: false,
      service: false,
    },
  });

  const whiteboard = ref({
    whiteBoards: [] as any[],
    whiteBoardData: [] as any[],
    isDirty: false, // set to TRUE to force reloading of whiteboard data when showing whiteboard
  });

  const documentTitle = useDocumentTitle('GAT-Ship');

  function setEmailListIsDirty(value: boolean) {
    if (typeof value === 'boolean') {
      emailListIsDirty.value = value;
    }
  }
  function setAppCriticalInitFinished(value: boolean) {
    appCriticalInitFinished.value = value;
  }

  function setAppTitle(title: string) {
    appTitle.value = title;
  }

  function setAppIsInitialized(value: boolean) {
    appIsInitialized.value = value;
  }

  function setLastError(error: any) {
    lastError.value = error;
  }

  function setNewAgency(value: number | null) {
    agencyIdSelected.value = value;
  }

  function setTraversableList(items: any[]) {
    traversableList.value.items = items;
  }

  // function setUnsavedDataOnCurrentPage(status:boolean) {
  //   unsavedDataOnCurrentPage.value = status;
  // }

  // function setUnsavedDialogPage(data:any) {
  //   unsavedDialogPage.value = data;
  // }

  function removePageSavedState(page: any) {
    const idx = unsavedPages.value.findIndex((item: any) => item.routeName == page.routeName);
    if (idx >= 0) {
      unsavedPages.value.splice(idx, 1);
    }
  }

  // IS MOVED TO EXPORTED FUNCTION CALL.
  // function removeFromLastClientUsed(clientId: string | number) {
  //   const lastUsedClients = [...clients.value.lastClientsUsed];
  //   const idx = lastUsedClients.findIndex((item) => item.ID == clientId);
  //   if (idx >= 0) {
  //     lastUsedClients.splice(idx, 1);
  //   }
  //   clients.value.lastClientsUsed = lastUsedClients;
  //   const savedInfo = {
  //     TypeName: 'web_last_clients',
  //     StrValue: JSON.stringify(lastUsedClients),
  //   };
  //   apiPost('savedInfo', savedInfo);
  // }

  // IS MOVED TO EXPORTED FUNCTION CALL.
  // function addToLastClientUsed(client: any) {
  //   const idx = clients.value.lastClientsUsed.findIndex((item) => item.ID == client.ID);
  //   if (idx >= 0) {
  //     clients.value.lastClientsUsed.splice(idx, 1);
  //   }
  //   clients.value.lastClientsUsed.unshift(client);
  //   if (clients.value.lastClientsUsed.length > 3) {
  //     clients.value.lastClientsUsed.pop();
  //   }
  //   // save to SavedInfo
  //   const clientsResult = [];
  //   // eslint-disable-next-line array-callback-return
  //   clients.value.lastClientsUsed.map((item) => {
  //     const obj = { ID: item.ID, NAME: item.NAME };
  //     clientsResult.push(obj);
  //   });
  //   const savedInfo = {
  //     TypeName: 'web_last_clients',
  //     StrValue: JSON.stringify(clients),
  //   };
  //   apiPost('savedInfo', savedInfo);
  // }

  function setLastClientsUsed(data: any[]) {
    clients.value.lastClientsUsed = data;
  }

  function setTraversableListIndex(index: number) {
    if (index < 0) {
      traversableList.value.showCounter = false;
    }
    traversableList.value.currentIndex = index + 1;
  }

  function setTraversableListCounter(value: boolean) {
    traversableList.value.showCounter = value;
  }

  function setPageSavedState(pageState: any) {
    const idx = unsavedPages.value.findIndex((item: any) => item.routeName == pageState.routeName);
    if (idx >= 0) {
      if (pageState.unSaved) {
        unsavedPages.value.splice(idx, 1, pageState);
      } else {
        unsavedPages.value.splice(idx, 1);
      }
    } else if (pageState.unSaved) {
      unsavedPages.value.push(pageState);
    }
  }

  function setIsCrm(value: boolean) {
    isCRM.value = value;
  }

  function setVarcharColumns(data: any) {
    varcharColumns.value = data;
  }

  const getCurrentAgency = computed(() => agencyIdSelected.value);

  function setAgencySettings(data: any[]) {
    agencySettings.value = data;
  }

  function setApiToken(token: string) {
    user.value.apiToken = token;
  }

  function setCrewChangeTablesDense(data: any) {
    user.value.userSettings.crewChange.denseTables = data;
  }

  function setCrewChangeTablesShow(data: any) {
    user.value.userSettings.crewChange.showTables = data;
  }

  function setDarkTheme(value: boolean) {
    localStorage.setItem('dark_theme', value.toString());
    settings.value.dark = value;
  }

  function setGlobalSettings(data: any) {
    globalSettings.value = data;
  }

  function setFeatureCodes(data: any) {
    featureCodes.value = data;
  }

  function setNumberFormat(value: string) {
    settings.value.numberFormat = value;
    numeral.locale(value);
  }

  function setDateFormat(value: string) {
    settings.value.dateFormat = value;
  }

  function setWhiteBoards(whiteBoards: any[]) {
    whiteboard.value.whiteBoards = whiteBoards;
  }

  function setWhiteBoardData(data: any[]) {
    whiteboard.value.whiteBoardData = data;
  }

  function setWhiteBoardDataIsDirty(isDirty: boolean) {
    whiteboard.value.isDirty = isDirty;
  }

  function setWhiteBoardUserSettings(whiteboardSettings: any) {
    user.value.userSettings.whiteBoards = whiteboardSettings;
  }

  function setUser(data: any) {
    const userResult = data;
    userResult.loaded = true;
    user.value = Object.assign(user.value, userResult);
    // state.application.agencyIdSelected = data.defaultAgencyId;
  }

  // function addVessel(vessel) {
  //   const idx = state.vessels.findIndex((item) => item.id == vessel.id);
  //   if (idx >= 0) {
  //     state.vessels[idx] = vessel;
  //   } else {
  //     state.vessels.push(vessel);
  //   }
  // }

  function setFavorites(data: any[]) {
    user.value.favorites = data;
  }

  function setUpcomingAccommodations(data: boolean) {
    user.value.upcomingTasks.accommodation = data;
  }

  function setUpcomingTransports(data: boolean) {
    user.value.upcomingTasks.transport = data;
  }

  function setUpcomingServices(data: any) {
    user.value.upcomingTasks.service = data;
  }

  // function toggelFavorite(fav) {
  //   const idx = user.value.favorites.findIndex((item) => item.FAV_TYPE == fav.FAV_TYPE && item.FAV_KEY == fav.FAV_KEY);
  //   if (idx >= 0) {
  //     apiDelete('favorites/', {
  //       recType: fav.FAV_TYPE,
  //       keyInt: fav.FAV_KEY,
  //       keyStr: '',
  //     });
  //     user.value.favorites.splice(idx, 1);
  //   } else {
  //     apiPost('favorites/', {
  //       recType: fav.FAV_TYPE,
  //       keyInt: fav.FAV_KEY,
  //       keyStr: '',
  //     });
  //     user.value.favorites.push({
  //       FAV_TYPE: fav.FAV_TYPE,
  //       FAV_KEY: fav.FAV_KEY,
  //     });
  //   }
  // }

  function getAgencySettings(agencyId: number) {
    const result = agencySettings.value.find((item) => item.ID == agencyId);
    if (result) {
      return result;
    }
    return {};
  }

  // user(state) {
  //   return state.user;
  // },

  const favCalls = computed(() => {
    const result: any[] = [];
    // eslint-disable-next-line array-callback-return
    user.value.favorites.map((item) => {
      if (item.FAV_TYPE == 'CALL') {
        result.push(item.FAV_KEY);
      }
    });
    return result;
  });

  const isProbablyAuthenticated = computed(() => {
    let result = false;
    try {
      if (user.value.apiToken) {
        const decoded = jwtDecode(user.value.apiToken) as any;
        if (decoded.scope == 'GatshipWebModule' && moment(decoded.validUntil).diff(moment(), 'days') > 0) {
          result = true;
          if (debugMode.value) {
            console.log(`token valid ${moment(decoded.validUntil).diff(moment(), 'days')} more days`);
          }
        } else if (debugMode.value) {
          console.log(decoded);
        }
      }
    } catch (error) {
      if (debugMode.value) {
        console.log(error);
      }
    }

    return result;
  });

  function userRight(propName: string) {
    if (!propName) {
      return true;
    }
    if (propName == 'Internal-only') {
      return user.value.internal;
    }

    // Rempve trailing number from delsystem. eg EX2.EDIT_PO_NUMBER will be EX.EDIT_PO_NUMBER
    // eslint-disable-next-line no-param-reassign
    propName = propName.replace(/\d+\./, '.');

    return (
      user.value.userRights &&
      // eslint-disable-next-line no-prototype-builtins
      user.value.userRights.hasOwnProperty(propName) &&
      user.value.userRights[propName] == '1'
    );
  }

  function getColumnLength(tableAndColumnName: string) {
    if (varcharColumns.value) {
      return varcharColumns.value[tableAndColumnName];
    }
    return 0; // if the settings are not loaded yet, we do not want to show "maxChar missing"
  }

  function hasFeatureCode(code: string) {
    if (code === undefined || !code) {
      return false;
    }

    const codes = featureCodes.value;
    const index = codes.findIndex((x) => x.CODE.toUpperCase() === code.toUpperCase());
    return index >= 0;
  }

  return {
    appCriticalInitFinished,
    appTitle,
    appIsInitialized,
    agencyIdSelected,
    debugMode,
    lastError,
    // unsavedDataOnCurrentPage,
    // unsavedDialogPage,
    unsavedPages,
    traversableList,
    clients,
    isCRM,
    version,
    varcharColumns,
    emailListIsDirty,
    appStatus,
    agencySettings,
    globalSettings,
    settings,
    user,
    whiteboard,
    documentTitle: skipHydrate(documentTitle),
    setEmailListIsDirty,
    setAppCriticalInitFinished,
    setAppTitle,
    setAppIsInitialized,
    setLastError,
    setNewAgency,
    setTraversableList,
    // setUnsavedDataOnCurrentPage,
    removePageSavedState,
    // removeFromLastClientUsed,
    // addToLastClientUsed,
    setLastClientsUsed,
    setTraversableListIndex,
    setTraversableListCounter,
    setPageSavedState,
    setIsCrm,
    setVarcharColumns,
    setAgencySettings,
    setApiToken,
    setCrewChangeTablesDense,
    setCrewChangeTablesShow,
    setDarkTheme,
    setGlobalSettings,
    setFeatureCodes,
    setNumberFormat,
    setDateFormat,
    setWhiteBoards,
    setWhiteBoardData,
    setWhiteBoardDataIsDirty,
    setWhiteBoardUserSettings,
    setUser,
    setFavorites,
    setUpcomingAccommodations,
    setUpcomingTransports,
    setUpcomingServices,
    getAgencySettings,
    userRight,
    getColumnLength,
    // Getters
    getCurrentAgency,
    favCalls,
    isProbablyAuthenticated,
    hasFeatureCode,
  };
});
